var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import loadingIcon from '../icons/loading.svg';
var WholePageLoadingWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.3);\n  img {\n    position: absolute;\n    top: calc(50% - 50px);\n    left: calc(50% - 50px);\n    width: 100px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.3);\n  img {\n    position: absolute;\n    top: calc(50% - 50px);\n    left: calc(50% - 50px);\n    width: 100px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"])));
var PageLoadingWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  img {\n    width: 75px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  img {\n    width: 75px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"])));
var WholePageLoading = function () {
    return (_jsx(WholePageLoadingWrapper, { children: _jsx("img", { src: loadingIcon, alt: "", width: "24px" }) }));
};
var PageLoading = function () {
    return (_jsx(PageLoadingWrapper, { children: _jsx("img", { src: loadingIcon, alt: "", width: "24px" }) }));
};
export { WholePageLoading, PageLoading };
var templateObject_1, templateObject_2;
