import { useEffect } from 'react';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { setGlobalTimezone } from '../../shared/date';
export var useApplyDayjsTimezone = function () {
    var timezone = useAppI18n().timezone;
    useEffect(function () {
        if (timezone) {
            setGlobalTimezone(timezone);
        }
    }, [timezone]);
};
