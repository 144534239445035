import commonEn from './en/common.json';
import commonJa from './ja/common.json';
import commonVi from './vi/common.json';
import componentsEn from './en/components';
import componentsJa from './ja/components';
import componentsVi from './vi/components';
import hooksEn from './en/hooks';
import hooksJa from './ja/hooks';
import hooksVi from './vi/hooks';
import sharedEn from './en/shared';
import sharedJa from './ja/shared';
import sharedVi from './vi/shared';
import containersEn from './en/containers';
import containersJa from './ja/containers';
import containersVi from './vi/containers';
export var resources = {
    ja: {
        common: commonJa,
        components: componentsJa,
        hooks: hooksJa,
        shared: sharedJa,
        containers: containersJa,
    },
    en: {
        common: commonEn,
        components: componentsEn,
        hooks: hooksEn,
        shared: sharedEn,
        containers: containersEn,
    },
    vi: {
        common: commonVi,
        components: componentsVi,
        hooks: hooksVi,
        shared: sharedVi,
        containers: containersVi,
    },
};
