var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Menubar } from './Menubar';
import colors from '../../shared/colors';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 960px;\n  margin: 0 auto;\n"], ["\n  min-width: 960px;\n  margin: 0 auto;\n"])));
var Main = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 40px;\n  box-sizing: border-box;\n  min-height: calc(100vh - 40px);\n  background: ", ";\n"], ["\n  padding-top: 40px;\n  box-sizing: border-box;\n  min-height: calc(100vh - 40px);\n  background: ", ";\n"])), colors.lightBlue);
var Layout = function (_a) {
    var children = _a.children;
    return (_jsxs(Wrapper, { children: [_jsx(Menubar, {}), _jsx(Main, { children: children })] }));
};
var TwoColumnContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  width: 100%;\n\n  > *:nth-child(1) {\n    width: 100%;\n  }\n  > *:nth-child(2) {\n    width: 300px;\n    flex-shrink: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  width: 100%;\n\n  > *:nth-child(1) {\n    width: 100%;\n  }\n  > *:nth-child(2) {\n    width: 300px;\n    flex-shrink: 0;\n  }\n"])), function (props) { var _a; return (_a = props.direction) !== null && _a !== void 0 ? _a : 'row'; });
var TwoColumnContent = function (_a) {
    var direction = _a.direction, children = _a.children;
    return _jsx(TwoColumnContentWrapper, { direction: direction, children: children });
};
export { Layout, TwoColumnContent };
var templateObject_1, templateObject_2, templateObject_3;
