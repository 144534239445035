import commons from './commons.json';
import settings from './settings.json';
import exportedList from './exportedList.json';
import jobList from './jobList.json';
import relatedBulkInput from './relatedBulkInput.json';
import importTranslation from './import.json';
import stampBulkInput from './stampBulkInput.json';
import replacement from './replacement.json';
import pageDetails from './pageDetails.json';
import search from './search.json';
export default {
    commons: commons,
    settings: settings,
    exportedList: exportedList,
    jobList: jobList,
    relatedBulkInput: relatedBulkInput,
    import: importTranslation,
    stampBulkInput: stampBulkInput,
    replacement: replacement,
    pageDetails: pageDetails,
    search: search,
};
