var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import colors from '../../shared/colors';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 52px;\n  width: 100%;\n  color: ", ";\n  background: ", ";\n"], ["\n  height: 52px;\n  width: 100%;\n  color: ", ";\n  background: ", ";\n"])), colors.white, colors.navy);
var Footer = function (_a) {
    var className = _a.className, children = _a.children;
    return _jsx(Wrapper, { className: className, children: children });
};
export { Footer };
var templateObject_1;
