import { notification } from 'antd';
import { createT } from '../i18n/utils';
var t = createT({ namespace: 'shared', keyPrefix: 'logs' });
var sendError = function (error) {
    if (process.env.ENVIRONMENT !== 'herodotus-prod') {
        console.error({ error: error });
    }
};
var showCommonErrorNotification = function (e) {
    notification.open({
        type: 'error',
        message: t('showCommonErrorNotification.message'),
        description: e.message,
    });
};
export { sendError, showCommonErrorNotification };
