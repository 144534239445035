var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var getHeight = function (_a) {
    var axis = _a.axis, size = _a.size;
    return (axis === 'x' ? 1 : size);
};
var getWidth = function (_a) {
    var axis = _a.axis, size = _a.size;
    return (axis === 'y' ? 1 : size);
};
var Spacer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", "block;\n  width: ", "px;\n  min-width: ", "px;\n  height: ", "px;\n  min-height: ", "px;\n"], ["\n  display: ", "block;\n  width: ", "px;\n  min-width: ", "px;\n  height: ", "px;\n  min-height: ", "px;\n"])), function (_a) {
    var axis = _a.axis;
    return (axis === 'x' ? 'inline-' : '');
}, getWidth, getWidth, getHeight, getHeight);
export { Spacer };
var templateObject_1;
