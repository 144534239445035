var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../shared/colors';
import logo from '../icons/logo-white.svg';
import importWhiteIcon from '../icons/import-white.svg';
import searchBlueIcon from '../icons/search-blue.svg';
import stampWhiteIcon from '../icons/stamp-white.svg';
import exportWhiteIcon from '../icons/export-white.svg';
import fileIcon from '../icons/file.svg';
import { Spacer } from './Spacer';
import { useAppI18n } from '../../contexts/AppI18nContext';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 40px;\n  position: fixed;\n  top: 0;\n  background: ", ";\n  display: flex;\n  align-items: center;\n  z-index: 1;\n"], ["\n  width: 100%;\n  height: 40px;\n  position: fixed;\n  top: 0;\n  background: ", ";\n  display: flex;\n  align-items: center;\n  z-index: 1;\n"])), colors.darkNavy);
var Text = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 40px;\n  color: ", ";\n  font-weight: bold;\n"], ["\n  font-size: 14px;\n  line-height: 40px;\n  color: ", ";\n  font-weight: bold;\n"])), function (props) { return props.color; });
var ChildText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  line-height: 40px;\n  color: ", ";\n"], ["\n  font-size: 12px;\n  line-height: 40px;\n  color: ", ";\n"])), function (props) { return props.color; });
var StyledLink = styled(function (_a) {
    var _ = _a.isActive, props = __rest(_a, ["isActive"]);
    return _jsx(Link, __assign({}, props));
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 40px;\n  border-bottom: 2px solid ", ";\n  transition: 0.3s;\n\n  &:hover {\n    border-color: ", ";\n  }\n"], ["\n  height: 40px;\n  border-bottom: 2px solid ", ";\n  transition: 0.3s;\n\n  &:hover {\n    border-color: ", ";\n  }\n"])), function (props) { return (props.isActive ? colors.blue : 'transparent'); }, colors.blue);
var FlexSpacer = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var SettingIconWrapper = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  height: 100%;\n"])));
var Menubar = function () {
    var location = useLocation();
    var isAuthenticated = useAuth0().isAuthenticated;
    var isI18nReady = useAppI18n().isI18nReady;
    var t = useTranslation('components', { keyPrefix: 'commons.menubar' }).t;
    var canDisplay = isAuthenticated && isI18nReady;
    var linkData = useMemo(function () {
        return canDisplay
            ? [
                {
                    type: 'text',
                    text: t('search'),
                    color: colors.blue,
                    icon: searchBlueIcon,
                    href: '/search',
                },
                {
                    type: 'category',
                    text: t('import'),
                    color: colors.white,
                    icon: importWhiteIcon,
                    children: [
                        { text: t('new_replacement'), href: '/' },
                        { text: t('list'), href: '/jobs/imported-list' },
                    ],
                },
                {
                    type: 'text',
                    text: t('export'),
                    color: colors.white,
                    icon: exportWhiteIcon,
                    href: '/jobs/exported-list',
                },
                {
                    type: 'text',
                    text: t('stamp'),
                    color: colors.white,
                    icon: stampWhiteIcon,
                    href: '/stamp-bulk-input',
                },
                {
                    type: 'text',
                    text: t('related-files'),
                    color: colors.white,
                    icon: fileIcon,
                    href: '/related-bulk-input',
                },
            ]
            : [];
    }, [canDisplay, t]);
    if (!canDisplay)
        return null;
    return (_jsxs(Wrapper, { children: [_jsx(Spacer, { axis: "x", size: 16 }), _jsx("img", { src: logo, alt: "" }), linkData.map(function (data) { return (_jsxs(React.Fragment, { children: [_jsx(Spacer, { axis: "x", size: 32 }), data.type === 'text' ? (_jsxs(StyledLink, { to: data.href, isActive: location.pathname === data.href, children: [(data.icon && _jsx("img", { src: data.icon, alt: "" })), _jsx(Spacer, { axis: "x", size: 6 }), _jsx(Text, { color: data.color, children: data.text })] })) : (_jsxs(_Fragment, { children: [_jsx("img", { src: data.icon, alt: "" }), _jsx(Spacer, { axis: "x", size: 6 }), _jsx(Text, { color: data.color, children: data.text }), data.children.map(function (child) { return (_jsxs(React.Fragment, { children: [_jsx(Spacer, { axis: "x", size: 16 }), _jsx(StyledLink, { to: child.href, isActive: location.pathname === child.href, children: _jsx(ChildText, { color: data.color, children: child.text }) })] }, child.text)); })] }))] }, data.text)); }), _jsx(FlexSpacer, {}), _jsx(StyledLink, { to: "/settings", isActive: location.pathname === '/settings', children: _jsx(SettingIconWrapper, { children: _jsx(SettingOutlined, { style: { fontSize: 24, color: colors.white } }) }) }), _jsx(Spacer, { axis: "x", size: 16 })] }));
};
export { Menubar };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
