var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SUPPORTED_TIMEZONES } from '../constants';
var TIMEZONE_KEY = 'timezone';
export var localTimezoneStorage = {
    getAll: function () {
        try {
            var value = window.localStorage.getItem(TIMEZONE_KEY);
            if (value === null)
                return {};
            var data = JSON.parse(value);
            if (typeof data !== 'object')
                return {};
            return Object.entries(data).reduce(function (result, _a) {
                var _b;
                var email = _a[0], timezone = _a[1];
                return SUPPORTED_TIMEZONES.includes(timezone) ? __assign(__assign({}, result), (_b = {}, _b[email] = timezone, _b)) : result;
            }, {});
        }
        catch (error) {
            console.warn(error);
            return {};
        }
    },
    get: function (email) {
        var values = this.getAll();
        return values[email];
    },
    set: function (email, timezone) {
        var _a;
        if (!SUPPORTED_TIMEZONES.includes(timezone)) {
            throw new Error("Please provide one of ".concat(SUPPORTED_TIMEZONES.join(','), " for timezone."));
        }
        var values = this.getAll();
        window.localStorage.setItem(TIMEZONE_KEY, JSON.stringify(__assign(__assign({}, values), (_a = {}, _a[email] = timezone, _a))));
    },
};
