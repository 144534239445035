import { jsx as _jsx } from "react/jsx-runtime";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { WholePageLoading } from '../components/commons';
import { withAppI18nRequired } from '../contexts/AppI18nContext';
export var ProtectedRoute = function (_a) {
    var component = _a.component;
    var Component = withAuthenticationRequired(withAppI18nRequired(component), {
        onRedirecting: WholePageLoading,
    });
    return _jsx(Component, {});
};
