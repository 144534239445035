import relatedBulkInput from './relatedBulkInput.json';
import importTranslation from './import.json';
import stampBulkInput from './stampBulkInput.json';
import pageDetails from './pageDetails.json';
import search from './search.json';
export default {
    relatedBulkInput: relatedBulkInput,
    import: importTranslation,
    stampBulkInput: stampBulkInput,
    pageDetails: pageDetails,
    search: search,
};
