var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useAsyncSelect } from '../../../hooks/commons';
var StyledAsyncSelect = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  box-sizing: border-box;\n"], ["\n  position: relative;\n  box-sizing: border-box;\n"])));
var StyledInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 200px;\n  box-sizing: border-box;\n"], ["\n  width: 200px;\n  box-sizing: border-box;\n"])));
var StyledListbox = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 200px;\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  z-index: 1;\n  position: absolute;\n  list-style: none;\n  background-color: white;\n  overflow: auto;\n  max-height: 200px;\n  border: 1px solid rgba(0, 0, 0, 0.25);\n  color: black;\n  font-size: 12px;\n  text-align: left;\n"], ["\n  width: 200px;\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  z-index: 1;\n  position: absolute;\n  list-style: none;\n  background-color: white;\n  overflow: auto;\n  max-height: 200px;\n  border: 1px solid rgba(0, 0, 0, 0.25);\n  color: black;\n  font-size: 12px;\n  text-align: left;\n"])));
var StyledOption = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 5px;\n\n  &:hover {\n    background-color: #2977f5;\n    color: white;\n  }\n"], ["\n  cursor: pointer;\n  padding: 5px;\n\n  &:hover {\n    background-color: #2977f5;\n    color: white;\n  }\n"])));
var StyledLoading = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 5px;\n"], ["\n  padding: 5px;\n"])));
export var AsyncSelect = function (props) {
    var _a = useAsyncSelect(props), rootProps = _a.rootProps, inputProps = _a.inputProps, listboxProps = _a.listboxProps, optionProps = _a.optionProps, isOpen = _a.isOpen, options = _a.options, loading = _a.loading;
    return (_jsxs(StyledAsyncSelect, { children: [_jsx("div", __assign({}, rootProps(), { children: _jsx(StyledInput, __assign({}, inputProps())) })), isOpen && (_jsx(StyledListbox, __assign({}, listboxProps(), { children: loading ? (_jsx(StyledLoading, { children: "..." })) : (options.map(function (option, index) { return (_jsx(StyledOption, __assign({}, optionProps({ index: index }), { children: props.getOptionLabel(option) }), props.getOptionLabel(option))); })) })))] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
