var colors = {
    white: '#fff',
    black: '#000',
    gray: '#dfe3e9',
    turquoise: '#00bbc7',
    darkGray: '#3d4559',
    middleGray: '#8a8a8a',
    darkNavy: '#111e34',
    ashBlue: '#7a8ebe',
    navy: '#1e2f4b',
    blue: '#1890ff',
    skyBlue: '#91d5ff',
    lightBlue: '#f8fbfe',
    lightAshBlue: '#cfd7e7',
    lightSkyBlue: '#e6f7ff',
    lightGray: '#f9fafc',
    neutral: '#d9d9d9',
    red: '#ff4d4f',
    cyan: '#1ac2c6',
    yellow: '#f2c94c',
    warning: '#faad14',
    error: '#f5222d',
};
export default colors;
