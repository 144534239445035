var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import colors from '../../shared/colors';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  line-height: 40px;\n  padding-left: 16px;\n  width: 100%;\n  color: ", ";\n  font-size: 16px;\n  font-weight: bold;\n  background: ", ";\n  box-shadow: 0px 2px 2px rgba(122, 142, 190, 0.1);\n"], ["\n  height: 40px;\n  line-height: 40px;\n  padding-left: 16px;\n  width: 100%;\n  color: ", ";\n  font-size: 16px;\n  font-weight: bold;\n  background: ", ";\n  box-shadow: 0px 2px 2px rgba(122, 142, 190, 0.1);\n"])), colors.darkGray, colors.white);
var Header = function (_a) {
    var children = _a.children, className = _a.className;
    return (_jsx(Wrapper, { className: className, "data-testid": "header", children: children }));
};
export { Header };
var templateObject_1;
