import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
var initialState = {
    replace: {
        replaceIdList: [],
        replacementPageGroupList: [],
    },
};
var AppStateContext = React.createContext(initialState);
var SetAppStateContext = React.createContext(function () { return void 0; });
export var useAppState = function () {
    return useContext(AppStateContext);
};
export var useSetAppState = function () {
    return useContext(SetAppStateContext);
};
export var AppStateProvider = function (props) {
    var _a;
    var _b = useState((_a = props.initialState) !== null && _a !== void 0 ? _a : initialState), state = _b[0], setState = _b[1];
    return (_jsx(AppStateContext.Provider, { value: state, children: _jsx(SetAppStateContext.Provider, { value: setState, children: props.children }) }));
};
