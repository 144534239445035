var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
var StyledCaution = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 auto;\n  font-size: 11px;\n  color: #8a8a8a;\n"], ["\n  margin: 0 auto;\n  font-size: 11px;\n  color: #8a8a8a;\n"])));
var Caution = function (_a) {
    var children = _a.children, className = _a.className;
    return (_jsxs(StyledCaution, { className: className, children: [_jsx("span", { role: "img", "aria-label": "!", children: "\uD83D\uDCA1" }), children] }));
};
export { Caution };
var templateObject_1;
