/* eslint-disable @typescript-eslint/ban-ts-comment */
//Migrate from antd v4 to v5
export var v4Theme = {
    token: {
        colorPrimary: '#1890ff',
        fontFamily: "\"Roboto Mono\", \"\u6E38\u30B4\u30B7\u30C3\u30AF Medium\", \"Yu Gothic Medium\", \"\u6E38\u30B4\u30B7\u30C3\u30AF\u4F53\", \"YuGothic\", \"\u30D2\u30E9\u30AE\u30CE\u89D2\u30B4 ProN W3\", \"Hiragino Kaku Gothic ProN\", \"\u30E1\u30A4\u30EA\u30AA\", \"Meiryo\", \"verdana\", sans-serif",
        borderRadius: 2,
    },
    components: {
        Card: {
            wireframe: true,
        },
        Tabs: {
            wireframe: true,
        },
        Modal: {
            wireframe: true,
        },
        Table: {
            wireframe: true,
        },
        Alert: {
            wireframe: true,
        },
        Button: {
            wireframe: true,
        },
        Input: {
            wireframe: true,
        },
        Checkbox: {
            wireframe: true,
        },
        Upload: {
            wireframe: true,
        },
        Radio: {
            wireframe: true,
        },
    },
};
