var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import cautionIcon from '../icons/caution.svg';
import colors from '../../shared/colors';
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  word-break: break-word;\n  > div {\n    overflow: auto;\n  }\n  .title {\n    font-weight: bold;\n    margin-bottom: 14px;\n    font-size: 16px;\n    img {\n      padding-right: 12px;\n    }\n  }\n  .content {\n    margin: 20px;\n    color: ", ";\n  }\n  .bottom {\n    text-align: right;\n    > button {\n      margin-left: 8px;\n    }\n  }\n"], ["\n  word-break: break-word;\n  > div {\n    overflow: auto;\n  }\n  .title {\n    font-weight: bold;\n    margin-bottom: 14px;\n    font-size: 16px;\n    img {\n      padding-right: 12px;\n    }\n  }\n  .content {\n    margin: 20px;\n    color: ", ";\n  }\n  .bottom {\n    text-align: right;\n    > button {\n      margin-left: 8px;\n    }\n  }\n"])), colors.darkGray);
var ConfirmModal = function (_a) {
    var title = _a.title, content = _a.content, isOpen = _a.isOpen, okText = _a.okText, cancelText = _a.cancelText, onCancel = _a.onCancel, onOk = _a.onOk;
    return (_jsxs(StyledModal, { open: isOpen, centered: true, width: 416, footer: null, closable: false, children: [_jsxs("p", { className: "title", children: [_jsx("img", { src: cautionIcon, alt: "" }), title] }), content && _jsx("p", { className: "content", children: content }), _jsxs("div", { className: "bottom", children: [_jsx(Button, { onClick: onCancel, children: cancelText }), _jsx(Button, { onClick: onOk, type: "primary", danger: true, children: okText })] })] }));
};
export { ConfirmModal };
var templateObject_1;
