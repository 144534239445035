import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { sendError } from './shared/logs';
/**
 * Adapt @auth0/auth0-react to work with react-router-dom v6
 *
 * Details:
 * https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-v6-app
 *
 * Code borrowed from:
 * https://github.com/adamjmcgrath/spa_react_typescript_hello-world_react-router-6/blob/navigate-component/src/auth0-provider-with-history.tsx
 */
var Auth0ProviderWithRedirectCallback = function (_a) {
    var _b;
    var children = _a.children;
    var navigate = useNavigate();
    var domain = (_b = process.env.AUTH0_URL) === null || _b === void 0 ? void 0 : _b.split('//')[1]; // auth0 only accepts domain.
    var clientId = process.env.AUTH0_CLIENT_ID;
    var audience = process.env.AUTH0_AUDIENCE;
    var onRedirectCallback = function (appState) {
        var _a;
        navigate((_a = appState === null || appState === void 0 ? void 0 : appState.returnTo) !== null && _a !== void 0 ? _a : window.location.pathname);
    };
    if (!(domain && clientId && audience)) {
        sendError('Environment variables for auth0 are not set!');
        return null;
    }
    return (_jsx(Auth0Provider, { domain: domain, clientId: clientId, onRedirectCallback: onRedirectCallback, authorizationParams: {
            // eslint-disable-next-line camelcase
            redirect_uri: window.location.origin,
            audience: audience,
        }, children: children }));
};
export default Auth0ProviderWithRedirectCallback;
