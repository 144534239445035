var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, DEFAULT_NAMESPACE } from '../constants';
import { resources } from '../resources';
export var options = {
    defaultNS: DEFAULT_NAMESPACE,
    lng: DEFAULT_LANGUAGE,
    ns: Object.keys(resources[DEFAULT_LANGUAGE]),
    fallbackLng: false,
    interpolation: {
        escapeValue: false,
    },
    returnNull: false,
};
/**
 * References https://react.i18next.com/getting-started#basic-sample
 */
i18n.use(initReactI18next).init(__assign(__assign({}, options), { resources: resources }));
export default i18n;
