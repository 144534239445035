import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppStateProvider } from './contexts/AppContext';
import { ConfigProvider } from 'antd';
import { Layout } from './components/commons/Layout';
import TagManager from 'react-gtm-module';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { ProtectedRoute } from './shared/ProtectedRoute';
import Auth0ProviderWithRedirectCallback from './Auth0ProviderWithRedirectCallback';
import { AppI18nProvider } from './contexts/AppI18nContext';
import { useApplyDayjsTimezone } from './hooks/commons/useApplyDayjsTimezone';
import { PageLoading } from './components/commons';
import { v4Theme } from './theme';
var Import = lazy(function () { return import('./containers/Import'); });
var ExportedList = lazy(function () { return import('./containers/ExportedList'); });
var Search = lazy(function () { return import('./containers/Search'); });
var PageDetails = lazy(function () { return import('./containers/PageDetails'); });
var RedirectToPageDetails = lazy(function () { return import('./containers/RedirectToPageDetails'); });
var BulkPageReplacement = lazy(function () { return import('./containers/Replacement/BulkPageReplacement/BulkPageReplacement'); });
var ReplacementSuggestionConfirmation = lazy(function () {
    return import('./containers/Replacement/ReplacementSuggestionConfirmation/ReplacementSuggestionConfirmation');
});
var JobList = lazy(function () { return import('./containers/JobList'); });
var PublishSettings = lazy(function () { return import('./containers/PublishSettings'); });
var ErrorDetail = lazy(function () { return import('./containers/ErrorDetail'); });
var StampBulkInput = lazy(function () { return import('./containers/StampBulkInput'); });
var RelatedBulkInput = lazy(function () { return import('./containers/RelatedBulkInput'); });
var Settings = lazy(function () { return import('./containers/Settings'); });
var StartAnalysis = lazy(function () { return import('./containers/StartAnalysis'); });
function AppSwitch() {
    var user = useAuth0().user;
    useEffect(function () {
        var userEmail = user === null || user === void 0 ? void 0 : user.email;
        if (!userEmail)
            return;
        TagManager.initialize({
            gtmId: 'GTM-MJN8BMB',
            dataLayer: {
                userEmail: userEmail,
            },
        });
    }, [user === null || user === void 0 ? void 0 : user.email]);
    useApplyDayjsTimezone();
    return (_jsx(Suspense, { fallback: _jsx(PageLoading, {}), children: _jsxs(Routes, { children: [_jsx(Route, { path: "/import", element: _jsx(ProtectedRoute, { component: Import }) }), _jsx(Route, { path: "/search", element: _jsx(ProtectedRoute, { component: Search }) }), _jsx(Route, { path: "/pages/replacement", element: _jsx(ProtectedRoute, { component: BulkPageReplacement }) }), _jsx(Route, { path: "/pages/:pageGroupId/:pageId", element: _jsx(ProtectedRoute, { component: PageDetails }) }), _jsx(Route, { path: "/pages/:pageGroupId", element: 
                    // It is assumed that Herodotus own internal links does not lead to here.
                    // Delete if there are no links to here from other services such as Klein.
                    _jsx(ProtectedRoute, { component: RedirectToPageDetails }) }), _jsx(Route, { path: "/confirm/replacement/suggestion/:importedFileId", element: _jsx(ProtectedRoute, { component: ReplacementSuggestionConfirmation }) }), _jsx(Route, { path: "/jobs/imported-list", element: _jsx(ProtectedRoute, { component: JobList }) }), _jsx(Route, { path: "/jobs/exported-list", element: _jsx(ProtectedRoute, { component: ExportedList }) }), _jsx(Route, { path: "/stamp-bulk-input", element: _jsx(ProtectedRoute, { component: StampBulkInput }) }), _jsx(Route, { path: "/related-bulk-input", element: _jsx(ProtectedRoute, { component: RelatedBulkInput }) }), _jsx(Route, { path: "/publish-settings", element: _jsx(ProtectedRoute, { component: PublishSettings }) }), _jsx(Route, { path: "/settings", element: _jsx(ProtectedRoute, { component: Settings }) }), _jsx(Route, { path: "/start-analysis", element: _jsx(ProtectedRoute, { component: StartAnalysis }) }), _jsx(Route, { path: "/", element: _jsx(ProtectedRoute, { component: Import }) }), _jsx(Route, { path: "/errors", children: _jsx(Route, { path: ":statusCode?", element: _jsx(ErrorDetail, {}) }) })] }) }));
}
function AppRouter() {
    return (_jsx(Router, { children: _jsx(Auth0ProviderWithRedirectCallback, { children: _jsx(AppI18nProvider, { children: _jsx(AppStateProvider, { children: _jsx(ConfigProvider, { autoInsertSpaceInButton: false, theme: v4Theme, children: _jsx(Layout, { children: _jsx(AppSwitch, {}) }) }) }) }) }) }));
}
export default AppRouter;
