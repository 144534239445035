import { useEffect, useRef, useState } from 'react';
export function useSelect(_a) {
    var optionsProps = _a.options, defaultValue = _a.defaultValue, getOptionLabel = _a.getOptionLabel, onInputChange = _a.onInputChange, onChange = _a.onChange, filterOptions = _a.filterOptions;
    var inputRef = useRef(null);
    var _b = useState(defaultValue || null), value = _b[0], setValueState = _b[1];
    var _c = useState(defaultValue ? getOptionLabel(defaultValue) : ''), inputValue = _c[0], setInputValueState = _c[1];
    var _d = useState(false), isOpen = _d[0], setIsOpenState = _d[1];
    var _e = useState([]), options = _e[0], setOptionsState = _e[1];
    useEffect(function () {
        var filteredOptions = isOpen
            ? optionsProps.filter(function (value) { return !filterOptions || filterOptions(value, inputValue); })
            : [];
        setOptionsState(filteredOptions);
    }, [isOpen, inputValue, optionsProps, filterOptions]);
    var handleInputValue = function (newValue) {
        var newInputValue = newValue === null ? '' : getOptionLabel(newValue);
        if (inputValue === newInputValue)
            return;
        setInputValueState(newInputValue);
        onInputChange === null || onInputChange === void 0 ? void 0 : onInputChange(newInputValue);
    };
    var handleValue = function (newValue) {
        if (value === newValue) {
            return;
        }
        if (onChange) {
            onChange(newValue);
        }
        setValueState(newValue);
    };
    var handleInputChange = function (event) {
        var newValue = event.target.value;
        if (inputValue !== newValue) {
            setInputValueState(newValue);
            if (onInputChange) {
                onInputChange(newValue);
            }
        }
        if (newValue === '') {
            handleValue(null);
        }
        if (!isOpen) {
            setIsOpenState(true);
        }
    };
    var handleFocus = function () {
        setIsOpenState(true);
    };
    var handleBlur = function () {
        handleInputValue(value);
        setIsOpenState(false);
    };
    var handleClick = function () {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    var handleOptionClick = function (event) {
        var index = Number(event.currentTarget.getAttribute('data-index'));
        var newOption = options[index];
        handleValue(newOption);
        handleInputValue(newOption);
        setIsOpenState(false);
    };
    var handleSelect = function (selectedOption) {
        handleValue(selectedOption);
        handleInputValue(selectedOption);
        setIsOpenState(false);
    };
    var handleInputMouseDown = function () {
        if (!isOpen) {
            setIsOpenState(true);
        }
    };
    return {
        rootProps: function () { return ({
            onClick: handleClick,
        }); },
        inputProps: function () { return ({
            value: inputValue,
            onBlur: handleBlur,
            onFocus: handleFocus,
            onMouseDown: handleInputMouseDown,
            onChange: handleInputChange,
            ref: inputRef,
        }); },
        listboxProps: function () { return ({
            onMouseDown: function (event) {
                event.preventDefault();
            },
        }); },
        optionProps: function (_a) {
            var index = _a.index;
            return {
                tabIndex: -1,
                onClick: handleOptionClick,
                'data-index': index,
            };
        },
        isOpen: isOpen,
        options: options,
        inputValue: inputValue,
        selected: value,
        onSelect: handleSelect,
    };
}
