import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
/**
 * References https://day.js.org/docs/en/plugin/timezone
 */
dayjs.extend(utc);
dayjs.extend(timezone);
var setGlobalTimezone = function (nextTimezone) {
    dayjs.tz.setDefault(nextTimezone);
};
var date = {
    format: function (value, format) { return dayjs(value).tz().format(format); },
    formatYYYYMMDD: function (value) { return date.format(value, 'YYYY/MM/DD'); },
    formatYYYYMMDDHHmm: function (value) { return date.format(value, 'YYYY/MM/DD HH:mm'); },
};
export { date, setGlobalTimezone };
