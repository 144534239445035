import i18n from '../config/language';
/**
 * Create `t` function to get translations outside of a React component
 */
export function createT(_a) {
    var _b = _a === void 0 ? {} : _a, namespace = _b.namespace, keyPrefix = _b.keyPrefix;
    var t = function (key, options) {
        if (options === void 0) { options = {}; }
        var finalKey = "".concat(key);
        if (keyPrefix) {
            finalKey = "".concat(keyPrefix, ".").concat(finalKey);
        }
        if (namespace) {
            finalKey = "".concat(namespace, ":").concat(finalKey);
        }
        return i18n.t(finalKey, options);
    };
    return t;
}
